{
  "name": "@magenty/source",
  "version": "0.0.1274",
  "license": "MIT",
  "scripts": {
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org magenty --project magenty dist/apps/magenty-ui/browser && sentry-cli sourcemaps upload --org magenty --project magenty dist/apps/magenty-ui/browser"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "19.0.5",
    "@angular/common": "19.0.5",
    "@angular/compiler": "19.0.5",
    "@angular/core": "19.0.5",
    "@angular/forms": "19.0.5",
    "@angular/platform-browser": "19.0.5",
    "@angular/platform-browser-dynamic": "19.0.5",
    "@angular/platform-server": "19.0.5",
    "@angular/router": "19.0.5",
    "@angular/ssr": "19.0.6",
    "@microsoft/clarity": "^1.0.0",
    "@nx/angular": "20.3.0",
    "@primeng/themes": "19.0.1",
    "@sentry/angular": "^9.1.0",
    "@sentry/cli": "^2.42.1",
    "@taiga-ui/addon-charts": "3.75.0",
    "@taiga-ui/addon-commerce": "3.75.0",
    "@taiga-ui/cdk": "3.75.0",
    "@taiga-ui/core": "3.75.0",
    "@taiga-ui/icons": "3.75.0",
    "@taiga-ui/kit": "3.75.0",
    "@tinymce/tinymce-angular": "7.0.0",
    "@types/leaflet": "^1.9.12",
    "crypto-js": "^4.2.0",
    "dexie": "^4.0.8",
    "driver.js": "^1.3.5",
    "exceljs": "^4.4.0",
    "file-saver": "^2.0.5",
    "fuse.js": "^7.0.0",
    "js2xmlparser": "^5.0.0",
    "jsbarcode": "^3.11.6",
    "leaflet": "^1.9.4",
    "ngx-json-viewer": "^3.2.1",
    "primeicons": "7.0.0",
    "primeng": "19.0.1",
    "rxjs": "~7.8.0",
    "tailwindcss-primeui": "^0.4.0",
    "tinymce": "^6.8.2",
    "tslib": "^2.3.0",
    "uuid": "^10.0.0",
    "zone.js": "0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "19.0.6",
    "@angular-devkit/core": "19.0.6",
    "@angular-devkit/schematics": "19.0.6",
    "@angular-eslint/eslint-plugin": "19.0.2",
    "@angular-eslint/eslint-plugin-template": "19.0.2",
    "@angular-eslint/template-parser": "19.0.2",
    "@angular/cli": "~19.0.0",
    "@angular/compiler-cli": "19.0.5",
    "@angular/language-service": "19.0.5",
    "@nx/devkit": "20.3.0",
    "@nx/eslint": "20.3.0",
    "@nx/eslint-plugin": "20.3.0",
    "@nx/jest": "20.3.0",
    "@nx/js": "20.3.0",
    "@nx/playwright": "20.3.0",
    "@nx/workspace": "20.3.0",
    "@playwright/test": "^1.36.0",
    "@schematics/angular": "19.0.6",
    "@swc-node/register": "1.9.1",
    "@swc/core": "1.5.7",
    "@types/crypto-js": "^4.2.2",
    "@types/express": "4.17.14",
    "@types/file-saver": "^2.0.7",
    "@types/jest": "29.5.14",
    "@types/node": "^18.16.9",
    "@types/uuid": "^10.0.0",
    "@typescript-eslint/eslint-plugin": "7.16.0",
    "@typescript-eslint/parser": "7.16.0",
    "@typescript-eslint/utils": "7.16.0",
    "eslint": "^8.57.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-jest": "^28.6.0",
    "eslint-plugin-playwright": "^0.15.3",
    "eslint-plugin-sonarjs": "^1.0.3",
    "jest": "29.7.0",
    "jest-environment-jsdom": "29.7.0",
    "jest-preset-angular": "14.4.2",
    "jsonc-eslint-parser": "^2.1.0",
    "nx": "20.3.0",
    "prettier": "^2.6.2",
    "ts-jest": "^29.1.0",
    "ts-node": "10.9.1",
    "typescript": "5.6.3"
  }
}
